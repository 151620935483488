import React, { Component } from 'react'
import Link from '../utils/link'

class ContactSlideout extends Component {

  state = {
    flyoutActive: false,
    visible: false,
  }

  render() {

    return (
      <>
        <section className='contact-slideout'>
          <div className="contact-slideout__inner">
            <Link className="close-tab" to='/contact/'>
              { this.state.flyoutActive ? 'Close' : 'Contact' }
            </Link>
          </div>
        </section>
      </>
    )
  }
}

export default ContactSlideout
