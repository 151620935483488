import React, { Component } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import Link from '../utils/link'

import FooterLogo from '../assets/images/footer-logo.svg'

import { Facebook, Instagram, YouTube, Arrow } from './icons'

class Footer extends Component {

	state = {
		result: ''
	}

	_handleChange = e => {
    this.setState({
      [`${e.target.name}`]: e.target.value,
    })
  }

  // 2. via `async/await`
  _handleSubmit = async (e) => {
    e.preventDefault();
    let data = {}
    const result = await addToMailchimp(this.state.email, data)
    // I recommend setting `result` to React state
    // but you can do whatever you want
    this.setState({
      result: result.result,
      msg: result.msg
    })
  }

  render() {
    let { wp } = this.props

    return (
      <section className='footer'>
        <div className='footer__inner'>
          <div className='footer__content'>
            <div className="logo"><img src={FooterLogo} alt="St Margaret's Berwick Grammar" /></div>
            <div className='links'>
              <h4>Quick Links</h4>
              <ul className='content-list'>
                { wp.siteSettings.acf.quicklinks && wp.siteSettings.acf.quicklinks.map((el, i) =>  (
                  <li key={i}><Link to={el.link}>{el.text}</Link></li>
                )) }
              </ul>
            </div>
            <div className='locations'>
              <h4>Locations</h4>
              <ul className='content-list'>
                { wp.siteSettings.acf.locations && wp.siteSettings.acf.locations.map((el, i) =>  (
                  <li key={i}>
                    {el.link && <Link to={el.link}>{el.location}</Link> }
                    {!el.link && <span>{el.location}</span> }
                  </li>
                )) }
              </ul>
            </div>
            <div className='socials'>
              <h4>Follow Us</h4>
              <form onSubmit={this._handleSubmit}>
                <input type='email' name='email' placeholder='Newsletter Sign Up' onChange={this._handleChange} />
                <button>
                  <Arrow color={'#FFF'} />
                </button>
              </form>
              <ul>
                <li><Link to={wp.siteSettings.acf.social.facebook}><Facebook color={'#fff'} /></Link></li>
                <li><Link to={wp.siteSettings.acf.social.instagram}><Instagram color={'#fff'} /></Link></li>
                <li><Link to={wp.siteSettings.acf.social.youtube}><YouTube color={'#fff'} /></Link></li>
              </ul>
            </div>
          </div>
					<div className='footer__info'>
						<p>© St Margaret’s Berwick Grammar {new Date().getFullYear()}</p>
						<p>
              Design by <Link to='https://atollon.com.au?utm_source=stmargarets.vic.edu.au&utm_campaign=attr&utm_content=footerlink&utm_medium=affiliate'>Atollon</Link>.
            </p>
					</div>
        </div>
      </section>
    )
	}
}

export default Footer
