/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onInitialClientRender = () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = 'async';
  script.src = 'https://cdn.rlets.com/capture_configs/624/1ba/72e/7294ad4b3cbe424ba1165a1.js';
  document.head.appendChild(script);
}
