import React, { Component } from 'react'
import Link from '../utils/link'
import he from 'he'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { Facebook, Instagram, YouTube, Email, Maps, Search, Phone } from './icons'
import Logo from '../assets/images/logo.svg'
import ScrolledLogo from '../assets/images/scrolled-logo.svg'

class Header extends Component {

  prevScrollPos = 0

  image_keys = {
    'Junior' : 'juniorSchoolImage',
    'ELC': 'earlyLearningCenterImage',
    'Girls': 'seniorGirlsImage',
    'Boys': 'seniorBoysImage',
  }

  state = {
    menuActive: false,
    visible: false,
    scrolled: false,
    invert: false,
    subMenus: [],
    triMenus: [],
    image: 'earlyLearningCenterImage',
  }

  // Adds an event listener when the component is mount.
  componentDidMount() {
    // Set default state of off-canvas primary menu
    let primary = this._renderMenu(this.props.menus.edges.find(el => el.node.slug === 'main-menu'))
    let subMenus = primary ? new Array(primary.length).fill(0) : []
    let triMenus = primary ? new Array(primary.length).fill([]) : []
    window.addEventListener("scroll", this.handleScroll)
    this.setState({ invert: this.props.context.template === 'post' || this.props.location.pathname.indexOf('news') > 0 || this.props.location.pathname.indexOf('wellbeing-hive') > 0 || this.props.location.pathname.indexOf('category') || this.props.location.pathname.indexOf('events') > 0, subMenus, triMenus })
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      // Trigger selectFocus after transition has completed
      this.setState({ invert: this.props.context.template === 'post' || this.props.location.pathname.indexOf('wellbeing-hive') > 0 })
    }
  }

  // Hide or show the menu.
  handleScroll = () => {
    const currentScrollPos = window.pageYOffset
    const visible = this.prevScrollPos < currentScrollPos && currentScrollPos > 100;
    const scrolled = currentScrollPos > window.innerHeight
    this.prevScrollPos = currentScrollPos
    this.setState({ visible, scrolled })
  }

  _hideOffCanvas = () => {
    this.setState({ menuActive: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ menuActive: !this.state.menuActive })
    document.querySelector('body').classList.toggle('menu-open')
  }

  _fixUrl(url) {
    let baseUrl = `stmargs-main.atollon.com.au`
    if (url.indexOf(baseUrl) !== -1) {
      return url.split(baseUrl)[1]
    }
    return url
  }

  _renderMenu = (menu) => {
    if (!menu || !menu.node.menuItems) return null
    let { menuItems } = menu.node
    let { subMenus, triMenus } = this.state

    return menuItems.nodes.map((el, i) => {

      if ( el.parentId ) return false

      let props = {
        to: this._fixUrl(el.url),
        className: el.childItems && subMenus[i] !== 0 ? 'active' : '',
        onClick: (event) => {
          if (el.childItems.nodes.length > 0) {
            event.preventDefault()

            let image = this.image_keys[el.label]
            if (!image) image = this.state.image

            if (subMenus[i] === 0) {
              subMenus[i] = el.childItems.nodes.length * 34
            } else {
              subMenus[i] = 0
              triMenus[i] = triMenus[i].map(() => 0)
            }

            this.setState({ subMenus, image })
          } else {
            this._hideOffCanvas()
          }
        }
      }

      return (
        <li key={i}>
          <Link {...props}>{ he.decode(el.label) }</Link>
          { el.childItems.nodes.length > 0 &&
            <ul style={{height: subMenus[i]}}>
              { el.childItems.nodes.map((sel, j) => {

                let subProps = {
                  onClick: this._hideOffCanvas,
                }

                if (sel.childItems.nodes.length > 0) {
                  subProps.className = `has-sub ${triMenus[i] && triMenus[i][j] ? 'active' : ''}`
                  subProps.onClick = (event) => {
                    event.preventDefault()
                    if (!triMenus[i][j]) {
                      triMenus[i][j] = sel.childItems.nodes.length * 34
                      subMenus[i] += triMenus[i][j]
                    } else {
                      subMenus[i] -= triMenus[i][j]
                      triMenus[i][j] = 0
                    }

                    this.setState({ triMenus, subMenus })
                  }
                }

                return (
                  <li key={j}>
                    { sel.label === 'Little Unicorns Playgroup' && <a href={this._fixUrl(sel.url)} {...subProps}>{ he.decode(sel.label) }</a> }
                    { sel.label !== 'Little Unicorns Playgroup' && <Link to={this._fixUrl(sel.url)} {...subProps}>{ he.decode(sel.label) }</Link> }
                    { sel.childItems.nodes.length > 0 &&
                      <ul style={{height: triMenus[i] && triMenus[i][j] || 0}}>
                        { sel.childItems.nodes.map((ssel, k) => (
                          <li key={k}>
                            <Link to={this._fixUrl(ssel.url)} onClick={this._hideOffCanvas}>{ he.decode(ssel.label) }</Link>
                          </li>
                        ))}
                      </ul>
                    }
                  </li>
                )
              })}
            </ul>
          }
        </li>
      )
    })

  }

  render() {
    let { menuActive, visible, scrolled, invert, image } = this.state
    const { menus, wp } = this.props

    let primary = this._renderMenu(menus.edges.find(el => el.node.slug === 'main-menu'))
    let secondary = this._renderMenu(menus.edges.find(el => el.node.slug === 'off-canvas'))

    let color = invert && !menuActive ? '#132A50' : '#FFFFFF'

    let className = 'header'
    if (visible && !menuActive) className += ' header--hidden'
    if (scrolled && !menuActive) className += ' header--scrolled'
    if (invert && !menuActive) className += ' header--invert'

    return (
      <>
        <header className={className}>
          <div className='header__inner'>

            <Link to='/' className='header__logo'>
              <img className='main' src={Logo} alt="St Margaret's Berwick Grammar" />
              <img className='scrolled' src={ScrolledLogo} alt="St Margaret's Berwick Grammar" />
            </Link>

            <div className='header__nav'>
              <ul>
                <li><Link to='tel:+61397038111'><Phone className="phone" color={color} /></Link></li>
                <li><Link to='https://google.com/maps/place/St+Margaret’s+and+Berwick+Grammar+School/@-38.0356891,145.347195,15z/data=!4m2!3m1!1s0x0:0x71c8351b95847a3b?sa=X&ved=2ahUKEwjO4KOe8OfsAhWawTgGHX9XADoQ_BIwE3oECA8QBQ'><Maps className="maps" color={color} /></Link></li>
                <li><Link to='mailto:info@smbg.vic.edu.au'><Email className="email" color={color} /></Link></li>
                <li><Link to='/search/'><Search className="search" color={color} /></Link></li>
              </ul>

              <a href='https://enrol.stmargarets.vic.edu.au/enrolment-application/start' className='btn-white' target="_blank" rel="noopener noreferrer">Enrol<span>&nbsp;Now</span></a>
              <a href='/school-tours/' className='btn-white'><span>Book a&nbsp;</span>tour</a>

              <button className={ this.state.menuActive ? 'hamburger active' : 'hamburger' } onClick={this._toggleOffCanvas}>
                <span className='lines'></span>
              </button>

            </div>
          </div>
        </header>

        <div className={`off-canvas ${menuActive ? 'active' : ''}`}>
          <nav className='off-canvas__nav'>
            <Link to='/' className='header__logo'>
              <img className='main' src={Logo} alt="St Margaret's Berwick Grammar" />
              <img className='scrolled' src={ScrolledLogo} alt="St Margaret's Berwick Grammar" />
            </Link>
            <ul className='off-canvas__nav__primary'>
              { primary }
            </ul>
            <ul className='off-canvas__nav__secondary'>
              { secondary }
            </ul>
            <ul className='off-canvas__nav__social'>
              <li><Link to={wp.siteSettings.acf.social.facebook}><Facebook color={'#fff'} /></Link></li>
              <li><Link to={wp.siteSettings.acf.social.instagram}><Instagram color={'#fff'} /></Link></li>
              <li><Link to={wp.siteSettings.acf.social.youtube}><YouTube color={'#fff'} /></Link></li>
            </ul>
          </nav>
          <button className={ this.state.menuActive ? 'hamburger active' : 'hamburger' } onClick={this._toggleOffCanvas}>
            <span className='lines'></span>
          </button>
        </div>

        <div className={`off-canvas__image ${menuActive ? 'active' : ''}`}>
          { Object.values(this.image_keys).map((el, i) => (
            <GatsbyImage key={i} className={image === el ? 'in' : 'out'} image={getImage(wp.siteSettings.acf[el].localFile)} alt={image.altText ? image.altText : ''} />
          )) }
        </div>

        <div className='header__padding' />

        <div className='sticky-footer'>
          <ul>
            <li><Link to='tel:+61397038111'><Phone className="phone" color={color} /></Link></li>
            <li><Link to='https://google.com/maps/place/St+Margaret’s+and+Berwick+Grammar+School/@-38.0356891,145.347195,15z/data=!4m2!3m1!1s0x0:0x71c8351b95847a3b?sa=X&ved=2ahUKEwjO4KOe8OfsAhWawTgGHX9XADoQ_BIwE3oECA8QBQ'><Maps className="maps" color={color} /></Link></li>
            <li><Link to='mailto:info@smbg.vic.edu.au'><Email className="email" color={color} /></Link></li>
            <li><Link to='/search/'><Search className="search" color={color} /></Link></li>
          </ul>
        </div>
          
      </>
    )
  }
}

export default Header
