import { useStaticQuery, graphql } from "gatsby"

const useLayoutQuery = () => {
  return useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        siteSettings {
          acf {
            earlyLearningCenterImage {
              sourceUrl
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1500
                  )
                }
              }
            }
            juniorSchoolImage {
              sourceUrl
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1500
                  )
                }
              }
            }
            seniorBoysImage {
              sourceUrl
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1500
                  )
                }
              }
            }
            seniorGirlsImage {
              sourceUrl
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1500
                  )
                }
              }
            }
            quicklinks {
              text
              link
            }
            locations {
              location
              link
            }
            social {
              facebook
              instagram
              twitter
              youtube
            }
          }
        }
      }
      menus: allWpMenu {
        edges {
          node {
            name
            slug
            menuItems {
              nodes {
                label
                url
                parentId
                childItems {
                  nodes {
                    url
                    label
                    childItems {
                      nodes {
                        url
                        label
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
}

export default useLayoutQuery