exports.components = {
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-wellbeing-hive-js": () => import("./../../../src/pages/wellbeing-hive.js" /* webpackChunkName: "component---src-pages-wellbeing-hive-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-berwick-js": () => import("./../../../src/templates/berwick.js" /* webpackChunkName: "component---src-templates-berwick-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-elc-js": () => import("./../../../src/templates/elc.js" /* webpackChunkName: "component---src-templates-elc-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-junior-js": () => import("./../../../src/templates/junior.js" /* webpackChunkName: "component---src-templates-junior-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-senior-js": () => import("./../../../src/templates/senior.js" /* webpackChunkName: "component---src-templates-senior-js" */)
}

